//存储数据
export function writeExpire(key, value, expire) {
  let obj = {
    time: new Date().getTime(),
    data: value,
    expire: expire,
  };
  let objStr = JSON.stringify(obj);
  localStorage.setItem(key, objStr);
}
//获取数据
export function readExpire(key) {
  let value = localStorage.getItem(key);
  if (!value || value === "null") {
    return value;
  }

  value = JSON.parse(value);
  if (Date.now() - value.time > value.expire * 1000) {
    localStorage.removeItem(key);
    return null;
  }

  return value.data;
}
