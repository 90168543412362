// import { createApp } from 'vue'
import { createRouter, createWebHashHistory } from "vue-router";
import { readExpire } from "@/utils/use_localStorage";

const Login = () => import("@/view/Login.vue");
const Register = () => import("@/view/Register.vue");
const Index = () => import("@/view/home/index.vue");
const Smart = () => import("@/view/smart/index.vue");
const BooksInfo = () => import("@/view/booksInfo/index.vue");
const StoryInfo = () => import("@/view/storyInfo/index.vue");
const Individual = () => import("@/view/individual/index.vue");
const Points = () => import("@/view/points/index.vue");
const HelpDocument = () => import("@/view/helpDocument/index.vue");
const Writing = () => import("@/view/writing/index.vue");
const ExtendLayout = () => import("@/layout/extendLayout.vue");

const routes = [
  {
    path: "/",
    redirect: "/writing",
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/register",
    name: "register",
    component: Register,
  },
  {
    path: "/writing",
    name: "writing",
    component: Writing,
  },
  {
    path: "/smart",
    name: "smart",
    component: Smart,
  },
  {
    path: "/booksInfo",
    name: "booksInfo",
    component: BooksInfo,
  },
  {
    path: "/storyInfo",
    name: "storyInfo",
    component: StoryInfo,
  },
  {
    path: "/individual",
    name: "individual",
    component: Individual,
  },
  {
    path: "/points",
    name: "points",
    component: Points,
  },
  {
    path: "/helpDocument",
    name: "helpDocument",
    component: HelpDocument,
  },
  {
    path: "/extend",
    name: "extend",
    component: ExtendLayout,
    redirect: "/extend/courtesy",
    children: [
      {
        path: "/extend/courtesy",
        name: "inviteCourtesy",
        component: () => import("@/view/invite/inviteCourtesy"),
      },
      {
        path: "/extend/list",
        name: "inviteList",
        component: () => import("@/view/invite/inviteList"),
      },
    ],
  },
  {
    path: "/index",
    name: "index",
    component: Index,
    redirect: "/create",
    children: [
      {
        path: "/create",
        name: "create",
        component: () => import("@/view/create"),
      },
      {
        path: "/books",
        name: "books",
        component: () => import("@/view/books"),
      },
      {
        path: "/story",
        name: "story",
        component: () => import("@/view/story"),
      },
      {
        path: "/forum",
        name: "forum",
        component: () => import("@/view/forum"),
      },
    ],
  },
];

export const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = "闲云"; //设置默认的标题
  let userInfo = readExpire("userInfo") || "";
  if (userInfo == null || userInfo == "") {
    //没有token就回到登录页
    if (to.name == "login" || to.path == "/" || to.name == "register" || to.name == "writing") {
      next();
    } else {
      next({ path: "/login" });
    }
  } else {
    if (to.name == "login" || to.path == "/" || to.name == "index" || to.name == "register" || to.name == "writing") {
      next({ path: "/index" });
    } else {
      next();
    }
  }
  // let token = userInfo.token;
  // if (token) {
  //   if (to.name == "login" || to.path == "/" || to.name == "index" || to.name == "register" || to.name == "writing") {
  //     next({ path: "/index" });
  //     // router.push(from.path)
  //   } else {
  //     next();
  //   }
  // } else {
  //   //没有token就回到登录页
  //   if (to.name == "login" || to.path == "/" || to.name == "register" || to.name == "writing") {
  //     next();
  //   } else {
  //     next({ path: "/login" });
  //   }
  // }
});
export default router;
