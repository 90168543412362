import { createApp } from "vue";
import App from "./App.vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import router from "./router/index";
import store from "@/store/index.js";
import "@imengyu/vue3-context-menu/lib/vue3-context-menu.css";
import ContextMenu from "@imengyu/vue3-context-menu";
import { drag } from "./directive/drag";
// import "@/utils/flexible";
const app = createApp(App);
app.use(ContextMenu);
app.use(router);
app.use(store);
app.use(ElementPlus);
app.mount("#app");
app.directive("drag", drag);
